import "antd/dist/antd.css";
import "../index.css";
import { Col, Row } from "antd";
import Card from "../components/Card";
import "../components/DropLeftMenu";
import {
  ReactChild,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import { Item } from "../interfaces/item";
import { useParams } from "react-router-dom";

const rowCardStyle = {
  marginBottom: "2em",
  width: "80%",
  margin: "auto",
};
const Items = () => {
  const { category } = useParams();
  const [items, setItems] = useState<Item[]>();

  useEffect(() => {
    fetch('http://localhost/rest/items.php?category1="' + category + '"')
      .then((res) => res.json().then((fetchedData) => setItems(fetchedData)))
      .catch((err) => console.log(err));
  }, []);
  /*useEffect(() => {
    document.title = `You clicked  times`;
  });*/
  return (
    <>
      {items && items.length > 0 && (
        <Row style={rowCardStyle}>
          <>
            {items.map((item) => {
              return (
                <Card
                  id={item.id}
                  name={item.name}
                  price={"" + item.price}
                  imgUrl={item.image_url}
                  path={category?.toLowerCase() + "/" + item.id}
                />
              );
            })}
          </>
        </Row>
      )}
    </>
  );
};

export default Items;
