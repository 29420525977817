import "../index.css";
import { wait } from "@testing-library/user-event/dist/utils";
import { Col, Row, Image, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { wrap } from "module";
import React, { CSSProperties, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Color } from "../interfaces/colors";
import { Item } from "../interfaces/item";
import "./Modal.css";
import check from "../images/check.png";
/**/

interface ImodalProps {
  selectedModalWrapping: any;
  updateModalWrapping: (arg: string) => void;
  selectedModalWrappingColor: any;
  updateModalWrappingColor: (arg: string) => void;
  selectedModalRibbonColor: any;
  updateModalRibbonColor: (arg: string) => void;
  selectedModalNote: any;
  updateModalNote: (arg: string) => void;
  setOpenModal: any;
}
const Modal: React.FC<ImodalProps> = ({
  selectedModalWrapping,
  updateModalWrapping,
  selectedModalWrappingColor,
  updateModalWrappingColor,
  selectedModalRibbonColor,
  updateModalRibbonColor,
  selectedModalNote,
  updateModalNote,
  setOpenModal,
}) => {
  const { id } = useParams();
  const [idColor, setIdColor] = useState<any>(0);
  const [isShown, setIsShown] = useState(false);

  const [previousSelectedWrapping, previousSetSelectedWrapping] =
    useState<string>("");
  const [selectedWrapping, setSelectedWrapping] = useState<string>("");
  const handleChangeWrapping = (value: any) => {
    document
      .getElementById(previousSelectedWrapping)
      ?.classList.remove("wrappingClicked");
    document
      .getElementById(previousSelectedWrapping)
      ?.classList.add("wrappingNotClicked");
    document.getElementById(value)?.classList.remove("wrappingNotClicked");
    document.getElementById(value)?.classList.add("wrappingClicked");
    previousSetSelectedWrapping(value);
    setSelectedWrapping(value);
  };

  const [selectedWrappingColor, setSelectedWrappingColor] =
    useState<string>("");
  const handleChangeWrappingColor = (value: any) => {
    setSelectedWrappingColor(value);
  };

  const [selectedRibbonColor, setSelectedRibbonColor] = useState<string>("");
  const handleChangeRibbonColor = (value: any) => {
    setSelectedRibbonColor(value);
  };

  const [note, setNote] = useState<string>("");
  const handleChangeNote = (value: any) => {
    setNote(value["target"]["value"]);
  };

  const [defaultButtonStyle, setDefaultButtonStyle] = useState<CSSProperties>({
    backgroundColor: "rgb(51, 153, 255)",
  });
  const [personalizedButtonStyle, setPersonalizedButtonStyle] =
    useState<CSSProperties>({
      backgroundColor: "rgb(255, 255, 255)",
    });
  const [description, setDescription] = useState<any>();
  const [wrappingColor, setWrappingColor] = useState<Color[]>();
  useEffect(() => {
    fetch("http://localhost/rest/Wrappings_Colors.php?id=" + idColor)
      .then((res) =>
        res.json().then((fetchedData) => setWrappingColor(fetchedData))
      )
      .catch((err) => console.log(err));
  }, [idColor]);

  const [ribbonColor, setRibbonColor] = useState<Color[]>();
  useEffect(() => {
    fetch("http://localhost/rest/Ribbons_Colors.php?id=" + idColor)
      .then((res) =>
        res.json().then((fetchedData) => setRibbonColor(fetchedData))
      )
      .catch((err) => console.log(err));
  }, [idColor]);

  const [wrapping, setWrapping] = useState<Item[]>();
  useEffect(() => {
    fetch("http://localhost/rest/Wrappings.php?id=" + id)
      .then((res) => res.json().then((fetchedData) => setWrapping(fetchedData)))
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <Col className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </Col>
        <Col className="title">
          <h1>Confezioni disponibili per questo prodotto</h1>
        </Col>
        {wrapping && wrapping.length > 0 && (
          <Row style={{ marginBottom: "1em", width: "100%", margin: "auto" }}>
            {wrapping.map((wrapping) => (
              <>
                <Col
                  xs={12}
                  sm={8}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={3}
                  style={{
                    marginBottom: "1em",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    setIdColor(wrapping.id);
                    setDescription(wrapping.info);
                    handleChangeWrapping(wrapping.name);
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="wrappingNotClicked"
                      id={wrapping.name}
                      src={check}
                    />
                    <Image
                      style={{
                        width: "150px",
                        height: "150px",
                        border: "2px",
                        borderStyle: "solid",
                        borderColor: "black",
                      }}
                      preview={false}
                      src={
                        "http://localhost/uploads/uploads/" + wrapping.image_url
                      }
                    />
                  </div>
                </Col>
              </>
            ))}
          </Row>
        )}
        <h3>Descrizione della confezione selezionata: {description}</h3>
        <h1>Colore confezione</h1>
        {wrappingColor && wrappingColor.length > 0 && (
          <Row>
            {wrappingColor.map((wrappingColor) => (
              <>
                <Col
                  onClick={() => {
                    handleChangeWrappingColor(wrappingColor.name);
                  }}
                  style={{
                    display: "inline-block",
                    width: "100px",
                    height: "100px",
                    border: "2px",
                    borderStyle: "solid",
                    borderColor: "black",
                    marginLeft: "50px",
                    alignItems: "center",
                    backgroundColor:
                      "rgb(" +
                      wrappingColor.R +
                      "," +
                      wrappingColor.G +
                      "," +
                      wrappingColor.B +
                      ")",
                  }}
                ></Col>
              </>
            ))}
          </Row>
        )}
        <h1>Colore nastro</h1>

        {ribbonColor && ribbonColor.length > 0 && (
          <Row>
            {ribbonColor.map((ribbonColor) => (
              <>
                <Col
                  onClick={() => {
                    handleChangeRibbonColor(ribbonColor.name);
                  }}
                  style={{
                    display: "inline-block",
                    width: "100px",
                    height: "100px",
                    border: "2px",
                    borderStyle: "solid",
                    borderColor: "black",
                    marginLeft: "50px",
                    alignItems: "center",
                    backgroundColor:
                      "rgb(" +
                      ribbonColor.R +
                      "," +
                      ribbonColor.G +
                      "," +
                      ribbonColor.B +
                      ")",
                  }}
                ></Col>
              </>
            ))}
          </Row>
        )}
        <h1>Bigliettino</h1>
        <Col>
          <button
            id="defaultButton"
            style={defaultButtonStyle}
            onClick={() => {
              setIsShown(false);
              setDefaultButtonStyle({ backgroundColor: "rgb(51, 153, 255)" });
              setPersonalizedButtonStyle({
                backgroundColor: "rgb(255, 255, 255)",
              });
            }}
          >
            Default
          </button>

          <button
            id="personalizedButton"
            style={personalizedButtonStyle}
            onClick={() => {
              setIsShown(true);
              setDefaultButtonStyle({ backgroundColor: "rgb(255, 255, 255)" });
              setPersonalizedButtonStyle({
                backgroundColor: "rgb(51, 153, 255)",
              });
            }}
          >
            Personalizzato -{">"} +0.20$
          </button>
        </Col>

        {isShown && (
          <Col
            xs={24}
            sm={14}
            md={11}
            lg={9}
            xl={7}
            xxl={6}
            style={{ marginTop: "1vw" }}
          >
            <TextArea
              onChange={handleChangeNote}
              showCount
              rows={3}
              placeholder={
                "Cosa scrivere nel bigliettino:\nfrasi, nomi, date ecc...\n"
              }
              maxLength={256}
            />
          </Col>
        )}

        <Col className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => (
              updateModalWrapping(selectedWrapping),
              updateModalWrappingColor(selectedWrappingColor),
              updateModalRibbonColor(selectedRibbonColor),
              updateModalNote(note),
              setOpenModal(false)
            )}
          >
            Continue
          </button>
        </Col>
      </div>
    </div>
  );
};

export default Modal;
