import "antd/dist/antd.css";
import "../index.css";
import { Col, Row } from "antd";
import Card from "../components/Card";
import "../components/DropLeftMenu";
import { useEffect, useState } from "react";
import { Category } from "../interfaces/category";

const rowCardStyle = {
  marginBottom: "2em",
};

const Home = ({ categories }: { categories: any }) => {
  return (
    <>
      {categories && categories.length > 0 && (
        <Row style={rowCardStyle}>
          {categories.map(
            (category: { id: number; name: string; image_url: string }) => (
              <Card
                id={category.id}
                name={category.name}
                price={""}
                imgUrl={category.image_url}
                path={category.name.toLowerCase()}
              />
            )
          )}
        </Row>
      )}
    </>
  );
};

export default Home;
