import { useCookies } from "react-cookie";
import { json } from "stream/consumers";
import { Product } from "../interfaces/product";

export interface CartProduct extends Product {
  counter: number;
}

const Cart = () => {
  const [cookies, setCookie] = useCookies(["cart"]);
  let cart: CartProduct[] = []; //inizializzo il carrello vuoto

  if (cookies["cart"] != undefined) {
    // carrello era già stato definito nei cookies, quindi lo carico nella variabile
    cart = cookies["cart"] as CartProduct[];
  }

  const tot = cart.reduce(
    (total, cartProd) => total + cartProd.counter * cartProd.price,
    0
  );

  const clearCart = () => {
    setCookie("cart", cart, { maxAge: -1 });
  };

  const clearCartItem = (id: number) => {
    cart = cart.filter((c) => c.id != id); //prendo tutti gli elementi diversi da id
    setCookie("cart", cart);
  };
  return (
    <div>
      <h5>
        <a href="/">TORNA INDIETRO</a>
      </h5>
      {cart.map((c) => (
        <div key={c.id}>
          <h4>
            {c.id}-{c.name}
          </h4>
          <h5>
            Ne hai presi {c.counter}, subtot: {(c.counter * c.price).toFixed(2)}
            € Colore: {c.color} Aggiunte:{" "}
            {JSON.parse(c.adding).map((add: any) => (
              <div>( {add} )</div>
            ))}
            Note: {c.note} Font: {c.font} Confezione: {c.wrapping} Colore
            confezione: {c.wrappingColor} Colore nastrino: {c.wrappingColor}{" "}
            Note confezione: {c.wrappingNote}
          </h5>
          <button onClick={() => clearCartItem(c.id)}>
            Togli elemento dal carrello
          </button>
        </div>
      ))}
      <h1 style={{ fontWeight: "bold", color: "red", background: "yellow" }}>
        TOT: {tot}€
      </h1>
      <button onClick={() => clearCart()}>SVUOTA CARRELLO</button>
    </div>
  );
};

export default Cart;
