import React, {
  Children,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "../index.css";
import { Col, Row, Layout, Typography, Input, Menu, Image } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUser,
  faHome,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import DropLeftMenu from "./DropLeftMenu";
import { Route } from "react-router-dom";
import Items from "../pages/Items";
import { Item } from "../interfaces/item";
import { ConsoleSqlOutlined, WindowsFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import background from "../images/wallpaper.jpg";
import headerLogo from "../images/PinkHeartLogo.png";

const { Header, Footer, Sider, Content } = Layout;
const { Search } = Input;

const headerStyle = {
  height: "200px",
  backgroundColor: "rgba(0, 0, 0, 0)",
  padding: "0px",
};

const toolbarStyle = {
  backgroundColor: "rgba(102, 51, 153, 0.3)",
  height: "40px",
  type: "flex",
};

const iconStyle = {
  color: "white",
  fontSize: "22px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const footerStyle = {
  backgroundColor: "rgba(114, 214, 247, 0.8)",
  height: "119px",
};

const AppWrapper = ({ content }: { content: ReactNode }) => {
  const [search, setSearch] = useState<any>("");
  const [item, setItem] = useState<Item[]>();
  const [collapsed, setCollapsed] = useState(true);
  const [siderHeight, setSiderHeight] = useState(0);
  const layoutRef = useRef<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    fetch("http://localhost/rest/Search_Items.php?name=" + search)
      .then((res) => res.json().then((fetchedData) => setItem(fetchedData)))
      .catch((err) => console.log(err));
    console.log(item);
  }, [search]);

  useLayoutEffect(() => {
    if (layoutRef.current)
      console.log(
        layoutRef.current.offsetHeight,
        layoutRef.current.clientHeight,
        layoutRef.current.scrollHeight
      );
    setSiderHeight(layoutRef.current ? layoutRef.current.offsetHeight : 0);
  }, []);
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Layout style={{ backgroundImage: `url(${background})` }}>
        <Header style={headerStyle}>
          {/*<img src={headerLogo} style={{width: "100vw", height: "200px"}}></img>*/}
        </Header>
        <Row style={toolbarStyle} className="fixed-component">
          <Col
            style={iconStyle}
            span={1}
            onClick={() => setCollapsed(!collapsed)}
          >
            <FontAwesomeIcon icon={faBars} />
          </Col>
          <Col style={iconStyle} span={1}>
            <a href="user">
              <FontAwesomeIcon icon={faUser} />
            </a>
          </Col>

          <Col style={iconStyle} span={1}>
            <a href="/">
              <FontAwesomeIcon icon={faHome} />
            </a>
          </Col>

          <Col style={iconStyle} span={1}>
            <a href="cart">
              <FontAwesomeIcon icon={faCartShopping} />
            </a>
          </Col>

          <Col span={16}>{/* testo bomboniere */}</Col>

          <Col span={4} style={iconStyle}>
            <Search
              placeholder="Cerca"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={() => {
                item?.map((item) => {
                  console.log(item.id);
                  navigate("/cerca?" + search);
                  if (window.location.pathname == "/cerca")
                    window.location.reload();
                });
              }}
              size="small"
              style={{
                width: 200,
              }}
            />
          </Col>
        </Row>
        <Row style={{ height: "30px"}}>
          <DropLeftMenu
            collapsed={collapsed}
            setCollapsed={(
              collapsed: boolean | ((prevState: boolean) => boolean)
            ) => setCollapsed(collapsed)}
          ></DropLeftMenu>
        </Row>
        <h1>{search}</h1>
        <Content style={{ zIndex: 0 }}>{content}</Content>

        <Footer style={footerStyle}></Footer>
      </Layout>
    </div>
  );
};

export default AppWrapper;
