import "antd/dist/antd.css";
import "../index.css";
import { Col, Row } from "antd";
import Card from "../components/Card";
import "../components/DropLeftMenu";
import { useEffect, useState } from "react";
import { Item } from "../interfaces/item";

const rowCardStyle = {
  height: "360px",
  marginBottom: "2em",
};

const AllProducts = () => {
  const [items, setItems] = useState<Item[]>();

  useEffect(() => {
    fetch("http://localhost/rest/items.php")
      .then((res) => res.json().then((fetchedData) => setItems(fetchedData)))
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      {items && items.length > 0 && (
        <Row style={rowCardStyle}>
          {items.map((item) => (
            <Card
              id={404}
              name={item.name}
              price={"" + item.price}
              imgUrl={item.image_url}
              path={item.name.toLowerCase()}
            />
          ))}
        </Row>
      )}
    </>
  );
};

export default AllProducts;
