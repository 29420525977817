import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "../index.css";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

function getItem(label: any, key: any, icon: any, children: any, type: any) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const MenuItems = (collapsed: boolean) => [
  getItem("Option 1", "1", collapsed ? null : <PieChartOutlined />, null, null),
  getItem("Option 2", "2", collapsed ? null : <DesktopOutlined />, null, null),
  getItem(
    "Option 3",
    "3",
    collapsed ? null : <ContainerOutlined />,
    null,
    null
  ),
  getItem(
    "Navigation One",
    "sub1",
    collapsed ? null : <MailOutlined />,
    [
      getItem("Option 5", "5", null, null, null),
      getItem("Option 6", "6", null, null, null),
      getItem("Option 7", "7", null, null, null),
      getItem("Option 8", "8", null, null, null),
    ],
    null
  ),
  getItem(
    "Navigation Two",
    "sub2",
    collapsed ? null : <AppstoreOutlined />,
    [
      getItem("Option 9", "9", null, null, null),
      getItem("Option 10", "10", null, null, null),
      getItem(
        "Submenu",
        "sub3",
        null,
        [
          getItem("Option 11", "11", null, null, null),
          getItem("Option 12", "12", null, null, null),
        ],
        null
      ),
    ],
    null
  ),
];

const DropLeftMenu = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Function;
}) => {
  const [selectedKey, setSelectedKey] = useState("1");
  const [zIndex, setZIndex] = useState(-1);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (collapsed) setTimeout(() => setZIndex(-1), 150);
    else setZIndex(2);
  }, [collapsed]);
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 2,
        minHeight: "100%",
        backgroundColor: "rgba(102, 51, 153, 0.3)",
      }}
    >
      <Menu
        className="DropLeftMenu"
        onSelect={({ item, key, keyPath, selectedKeys, domEvent }) =>
          setSelectedKey(key)
        }
        style={{ width: collapsed ? "0px" : "300px", zIndex: zIndex, backgroundColor: "rgba(102, 51, 153, 0)" }}
        defaultOpenKeys={["sub1"]}
        selectedKeys={collapsed ? [] : [selectedKey]}
        mode="inline"
        theme="dark"
        items={MenuItems(collapsed)}
      />
    </div>
  );
};

export default DropLeftMenu;
