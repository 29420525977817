import "../index.css";
import { Button, Col, Row, Select, Typography } from "antd";
import Card from "../components/Card";
import "../components/DropLeftMenu";
import { useEffect, useState } from "react";
import { Item } from "../interfaces/item";
import { Color } from "../interfaces/colors";
import { useParams } from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { Image } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Modal from "../components/Modal";
import { Font } from "../interfaces/fonts";
import { useCookies, Cookies } from "react-cookie";
import { CartProduct } from "./Cart";
import { Adding } from "../interfaces/adding";
import { stringify } from "querystring";
import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { ItemType } from "antd/lib/menu/hooks/useItems";

const rowCardStyle = {
  marginBottom: "2em",
};

const imageStyle = {
  height: "200px",
  width: "100%",
};

const GetColors = () => {
  const { id } = useParams();
  const [color, setColor] = useState<Color[]>();
  useEffect(() => {
    fetch("http://localhost/rest/Colors.php?id=" + id)
      .then((res) => res.json().then((fetchedData) => setColor(fetchedData)))
      .catch((err) => console.log(err));
  }, []);
  console.log(color);
};
function getItem(label: string, key: string, icon?: any, children?: any[]) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("Option 1", "1", <PieChartOutlined />),
  getItem("Option 2", "2", <DesktopOutlined />),
  getItem("User", "sub1", <UserOutlined />, [
    getItem("Tom", "3"),
    getItem("Bill", "4"),
    getItem("Alex", "5"),
  ]),
  getItem("Team", "sub2", <TeamOutlined />, [
    getItem("Team 1", "6"),
    getItem("Team 2", "8"),
  ]),
  getItem("Files", "9", <FileOutlined />),
];

const navItems: ItemType[] = [{ key: "sm", label: "Culo" }];
const ItemPage = () => {
  const [cookies, setCookie] = useCookies(["cart"]);

  const addToCart = (
    product: any,
    selectedColor: any,
    selectedAdding: string[],
    note: string,
    selectedFont: string,
    selectedWrapping: string,
    selectedWrappingColor: string,
    selectedRibbonColor: string,
    wrappingNote: string
  ) => {
    console.log("ADD TO CART -> " + wrappingNote);
    let cart: CartProduct[] = []; //inizializzo il carrello vuoto

    if (cookies["cart"] != undefined) {
      // carrello era già stato definito nei cookies, quindi lo carico nella variabile
      cart = cookies["cart"] as CartProduct[];
    }

    const isProductInCart = cart.filter((c) => c.id == product.id).length > 0;
    if (isProductInCart) {
      //elemento già aggiunto, incremento il contatore
      cart.filter((c) => c.id == product.id)[0].counter++;
    } else {
      //elemento aggiunto ora, contatore ad 1
      product = {
        ...product,
        color: selectedColor,
        note: note,
        adding: JSON.stringify(selectedAdding),
        font: selectedFont,
        wrapping: selectedWrapping,
        wrappingColor: selectedWrappingColor,
        ribbonColor: selectedRibbonColor,
        wrappingNote: wrappingNote,
      };

      const newCartItem: CartProduct = { ...product, counter: 1 };
      cart.push(newCartItem);
    }
    console.log(product);
    setCookie("cart", cart, { path: "/" });
    console.log(cookies["cart"]);
  };

  const { id } = useParams();
  const { Option } = Select;
  const [modalOpen, setModalOpen] = useState(false);
  const [colorSelected, setColorSelected] = useState();
  const [addingSelected, setAddingSelected] = useState<string[]>([]);
  const [note, setNote] = useState<string>("");
  const [selectedFont, setSelectedFont] = useState<string>("");
  const handleChangeFill = (value: any) => {
    setColorSelected(value);
  };
  const handleChangeAdding = (value: any) => {
    setAddingSelected(value);
  };
  const handleChangeNote = (value: any) => {
    setNote(value["target"]["value"]);
  };

  const handleChangeFont = (value: any) => {
    setSelectedFont(value);
  };
  const [item, setItem] = useState<Item[]>();
  useEffect(() => {
    fetch("http://localhost/rest/Single_Items.php?id=" + id)
      .then((res) => res.json().then((fetchedData) => setItem(fetchedData)))
      .catch((err) => console.log(err));
  }, []);

  const [adding, setAdding] = useState<Adding[]>();
  useEffect(() => {
    fetch("http://localhost/rest/Addings.php?id=" + id)
      .then((res) => res.json().then((fetchedData) => setAdding(fetchedData)))
      .catch((err) => console.log(err));
  }, []);

  const [color, setColor] = useState<Color[]>();
  useEffect(() => {
    fetch("http://localhost/rest/Colors.php?id=" + id)
      .then((res) => res.json().then((fetchedData) => setColor(fetchedData)))
      .catch((err) => console.log(err));
  }, []);

  const [font, setFont] = useState<Font[]>();
  useEffect(() => {
    fetch("http://localhost/rest/items_fonts.php?id=" + id)
      .then((res) => res.json().then((fetchedData) => setFont(fetchedData)))
      .catch((err) => console.log(err));
  }, []);
  const [selectedWrapping, setSelectedWrapping] = useState<string>("");
  const updateWrapping = (wrapping: string): void => {
    setSelectedWrapping(wrapping);
  };
  const [selectedWrappingColor, setSelectedWrappingColor] =
    useState<string>("");
  const updateWrappingColor = (wrappingColor: string): void => {
    setSelectedWrappingColor(wrappingColor);
  };
  const [selectedRibbonColor, setSelectedRibbonColor] = useState<string>("");
  const updateRibbonColor = (ribbonColor: string): void => {
    setSelectedRibbonColor(ribbonColor);
  };

  const [wrappingNote, setWrappingNote] = useState<string>("");
  const updateWrappingNote = (wrappingNote: string): void => {
    setWrappingNote(wrappingNote);
  };

  console.log(item);
  return (
    <Row
      style={{
        backgroundColor: "rgba(255, 255, 255, 1)",
        marginLeft: "2em",
        marginRight: "2em",
        marginBottom: "2em",
      }}
    >
      {modalOpen && (
        <Modal
          setOpenModal={setModalOpen}
          selectedModalWrapping={selectedWrapping}
          updateModalWrapping={updateWrapping}
          selectedModalWrappingColor={selectedWrappingColor}
          updateModalWrappingColor={updateWrappingColor}
          selectedModalRibbonColor={selectedRibbonColor}
          updateModalRibbonColor={updateRibbonColor}
          selectedModalNote={wrappingNote}
          updateModalNote={updateWrappingNote}
        />
      )}
      {item && item.length > 0 && (
        <div style={{ width: "95%", margin: "auto" }}>
          {item.map((item) => (
            <>
              <Title
                level={1}
                style={{
                  fontFamily: "Fredoka One , cursive",
                  marginTop: "1vw",
                }}
              >
                {item.id}
                {") "}
                {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
              </Title>

              <Col style={{ display: "flex" }} xxl={24}>
                <Image
                  style={imageStyle}
                  src={"http://localhost/uploads/uploads/" + item.image_url}
                />
                <Title level={4} style={{ width: "35%", marginLeft: "30px" }}>
                  {item.info}
                </Title>
              </Col>
              <Row>
                <Col>
                  {color && color.length > 0 && (
                    <div>
                      <Title
                        level={3}
                        style={{
                          fontFamily: "Baloo 2 , cursive",
                          marginTop: "1vw",
                        }}
                      >
                        {"Colore di riempimento"}
                      </Title>

                      <Select
                        defaultValue="Riemp"
                        style={{
                          width: 120,
                        }}
                        onChange={handleChangeFill}
                      >
                        {color.map((color) => (
                          <Option value={color.name}>{color.name}</Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  {adding && adding.length > 0 && (
                    <div>
                      <Title
                        level={3}
                        style={{
                          fontFamily: "Baloo 2 , cursive",
                          marginTop: "1vw",
                        }}
                      >
                        {"Aggiunte"}
                      </Title>
                      <Select
                        mode="multiple"
                        placeholder="Seleziona una o più aggiunte"
                        style={{
                          width: 300,
                        }}
                        onChange={handleChangeAdding}
                      >
                        {adding.map((adding) => (
                          <Option value={adding.name}>{adding.name}</Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </Col>
                {font && font.length > 0 && (
                  <Row style={{ width: "63%" }}>
                    {font.map((font) => (
                      <Col
                        xs={10}
                        sm={8}
                        md={6}
                        lg={5}
                        xl={4}
                        xxl={3}
                        style={{
                          marginTop: "30px",
                          marginLeft: "10px",
                          marginBottom: "1em",
                          textAlign: "center",
                        }}
                      >
                        <Image
                          style={{
                            width: "100px",
                            height: "100px",
                            border: "2px",
                            borderStyle: "solid",
                            borderColor: "black",
                          }}
                          onClick={() => handleChangeFont(font.name)}
                          preview={false}
                          src={"http://localhost/fonts/" + font.name + ".jpg"}
                        ></Image>
                      </Col>
                    ))}
                  </Row>
                )}
              </Row>

              <Col
                xs={24}
                sm={14}
                md={11}
                lg={9}
                xl={7}
                xxl={6}
                style={{ marginTop: "1vw" }}
              >
                <TextArea
                  showCount
                  rows={3}
                  placeholder={
                    "Scrivere eventuali note come, ad esempio, il nome o il soggetto da inserire. Ulteriori dettagli si potranno chiarire in privato dopo la conferma dell'ordine "
                  }
                  maxLength={256}
                  onChange={handleChangeNote}
                />
              </Col>
              <button
                className="openModalBtn"
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Open
              </button>
              <button
                style={{ marginTop: "1em" }}
                onClick={() =>
                  addToCart(
                    item,
                    colorSelected,
                    addingSelected,
                    note,
                    selectedFont,
                    selectedWrapping,
                    selectedWrappingColor,
                    selectedRibbonColor,
                    wrappingNote
                  )
                }
              >
                Aggiungi al carrello
              </button>
            </>
          ))}
        </div>
      )}
    </Row>
  );
};
export default ItemPage;
