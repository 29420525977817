import { Alert, Col } from "antd";
import { Image } from "antd";


const imageStyle = {
  height: "200px",
};

const Card = ({
  id,
  name,
  price,
  imgUrl,
  path,
}: {
  id: number;
  name: string;
  price: string;
  imgUrl: string;
  path: string;
}) => {
  return (
    <>
      <Col
        xs={24}
        sm={12}
        md={8}
        lg={6}
        xl={6}
        xxl={4}
        id="card"
        style={{
          textAlign: "center",
          padding: "1em",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            textAlign: "center",
            padding: "1em",
          }}
        >
          <a href={path}>
            <Image
              style={imageStyle}
              preview={false}
              src={"http://localhost/uploads/uploads/" + imgUrl}
            />
            <h1>{name}</h1>
            <h1>{price}</h1>
          </a>
        </div>
      </Col>
    </>
  );
};
export default Card;

//<a href={"http://localhost/rest/subcategories.php?id=" + id}></a>
