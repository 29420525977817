import React, {
  Children,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import { Col, Row, Layout, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUser,
  faHome,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import { Input } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DropLeftMenu from "./components/DropLeftMenu";
import Card from "./components/Card";
import "./components/DropLeftMenu";
import AppWrapper from "./components/AppWrapper";
import Home from "./pages/Home";
import Categories from "./pages/Categories";
import AllProducts from "./pages/AllProducts";
import Items from "./pages/Items";
import { Product } from "./interfaces/product";
import SearchPage from "./pages/SearchPage";
import ItemPage from "./pages/Item";
import { Category } from "./interfaces/category";
import { CookiesProvider, useCookies } from "react-cookie";
import Cart from "./pages/Cart";

function App() {
  const [categories, setCategories] = useState<Category[]>();

  useEffect(() => {
    fetch("http://localhost/rest/All_categories.php")
      .then((res) =>
        res.json().then((fetchedData) => setCategories(fetchedData))
      )
      .catch((err) => console.log(err));
  }, []);

  const [subcategories, setSubcategories] = useState<Category[]>();

  useEffect(() => {
    fetch("http://localhost/rest/All_subcategories.php")
      .then((res) =>
        res.json().then((fetchedData) => setSubcategories(fetchedData))
      )
      .catch((err) => console.log(err));
  }, []);
  return (
    <BrowserRouter>
      <CookiesProvider>
        <Routes>
          <Route
            index
            element={<AppWrapper content={<Home categories={categories} />} />}
          />
          <Route
            path="user"
            element={<AppWrapper content={<AllProducts></AllProducts>} />}
          />
          <Route
            path={"/:refCateg"}
            element={<AppWrapper content={<Categories></Categories>} />}
          />
          <Route
            path={"/:refCateg" + "/:category"}
            element={<AppWrapper content={<Items></Items>} />}
          />
          <Route
            path={"/:refCateg" + "/:category" + "/:id"}
            element={<AppWrapper content={<ItemPage></ItemPage>} />}
          />
          <Route
            path="cerca"
            element={<AppWrapper content={<SearchPage></SearchPage>} />}
          />
          <Route
            path={"cart"}
            element={<AppWrapper content={<Cart></Cart>} />}
          />
        </Routes>
      </CookiesProvider>
    </BrowserRouter>
  );
}

export default App;
function setSiderHeight(arg0: any) {
  throw new Error("Function not implemented.");
}
