import "antd/dist/antd.css";
import "../index.css";
import { Col, Row } from "antd";
import Card from "../components/Card";
import "../components/DropLeftMenu";
import { useEffect, useState } from "react";
import { Category } from "../interfaces/category";
import { useParams } from "react-router-dom";
import Search from "antd/lib/input/Search";

const rowCardStyle = {
  height: "360px",
  marginBottom: "2em",
};

const SearchPage = () => {
  const { refCateg } = useParams();
  const { category } = useParams();
  const [categories, setCategories] = useState<Category[]>();
  console.log(window.location.search.slice(1));
  const routePath =
    "http://localhost/rest/Search_Items.php?name=" +
    window.location.search.slice(1);

  useEffect(() => {
    fetch(routePath)
      .then((res) =>
        res.json().then((fetchedData) => setCategories(fetchedData))
      )
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      {categories && categories.length > 0 && (
        <Row style={rowCardStyle}>
          <h1>{refCateg}</h1>
          {categories.map((category) => (
            <Card
              id={category.id}
              name={category.name}
              price={""}
              imgUrl={category.image_url}
              path={refCateg?.toLowerCase() + "/" + category.name.toLowerCase()}
            />
          ))}
        </Row>
      )}
    </>
  );
};

export default SearchPage;
